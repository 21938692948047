<template>
  <v-list-item v-on="eventHandler">
    <v-list-item-avatar v-if="iconName">
      <document-ref-icon
        visible
        color="primary"
        :icon-name="iconName"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="d-flex">
        <div
          class="text-truncate histo-text "
          v-html="item.text"
        ></div>
        <v-spacer />
        <calendar-date
          class="grey--text font-weight-regular"
          :date="item.date"
          timeline
        />
      </v-list-item-title>
      <v-list-item-subtitle class="font-weight-regular">
        <user-ref
          hide-group
          hide-email
          hide-phone
          :avatar-props="{visible: false}"
          :id="item.userId"
        />
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="font-weight-regular"
        v-if="item.comment"
      >
        <div
          class="text-truncate histo-text "
          v-html="item.comment"
          v-if="item.comment.length < 50"
        ></div>
        <v-tooltip
          v-else
          bottom
        >
          <template v-slot:activator="{ on }">
            <div
              class="text-truncate histo-text "
              v-html="item.comment"
              v-on="on"
            ></div>
          </template>
          <div
            class="text-tooltip"
            v-html="item.comment"
          ></div>
        </v-tooltip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import navigation from '@/navigationController'

export default {
  components: {
    CalendarDate: () => import('@/components/calendar-date'),
    DocumentRefIcon: () => import('@/components/document-ref-icon'),
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  computed: {
    iconName () {
      if (!this.item.type) { return }
      return `i.${this.toPascalCase(this.item.type)}`
    },
    eventHandler () {
      const ty = this.item.type ?? this.item.navId?.type

      if (this.item.navId && ty !== 'work-items') {
        return { click: ty !== 'files' ? this.navigateTo : this.downloadFile }
      } else { return {} }
    }
  },
  methods: {
    async navigateTo () {
      const id = this.item.navId?.id ?? this.item.navId
      const type = this.item.type ?? this.item.navId?.type
      await navigation.navigateTo(id, type, null)
    },
    async downloadFile () {
      await navigation.downloadFile(this.item.navId)
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.histo-text
  min-width 100px
  max-width 80%

.text-tooltip
  white-space pre-line
</style>
