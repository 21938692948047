import { render, staticRenderFns } from "./history-line.vue?vue&type=template&id=65afa2d6&scoped=true&"
import script from "./history-line.vue?vue&type=script&lang=js&"
export * from "./history-line.vue?vue&type=script&lang=js&"
import style0 from "./history-line.vue?vue&type=style&index=0&id=65afa2d6&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65afa2d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VSpacer,VTooltip})
